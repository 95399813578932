import { useNavigate } from "react-router-dom"
import Logoimage from "../assets/images/brand/Logo.svg"
import LogoimageWhite from "../assets/images/brand/Logo_White.svg"

const Logo = ({ variation, ...props }) => {
  const navigate = useNavigate()
  const handleRedirect = () => navigate("/")
  return variation && variation === "white" ? (
    <img
      onClick={handleRedirect}
      className="w-40 h-2w-40 cursor-pointer"
      {...props}
      src={LogoimageWhite}
      alt="Patients Edge Logo"
    />
  ) : (
    <img
      onClick={handleRedirect}
      className="w-40 cursor-pointer h-2w-40"
      {...props}
      src={Logoimage}
      alt="Patients Edge Logo"
    />
  )
}

export default Logo
