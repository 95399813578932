import { useModal } from "../hooks"
import { Accessibility } from "./Accessibility"
import { FooterPopupInformation } from "./FooterPopupInformation"
import LogoVariation from "./LogoVariation"
import { PrivacyPolicy } from "./PrivacyPolicy"
import { TermsAndConditions } from "./TermsAndConditions"
import { Link } from "./templates/Link"
import { Modal } from "./templates/Modal"

export const Footer = () => {
  const { closeModal, openModal, modalState } = useModal()
  return (
    <footer className="bg-[#E8E6E6] py-20 flex justify-center">
      <LogoVariation />
      <div className="ml-8">
        <ul className="space-y-2">
          <li>
            <Link onClick={() => openModal("modalPrivacy")} to="#">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link onClick={() => openModal("modalAccessibility")} to="#">
              Accessibility
            </Link>
          </li>
          <li>
            <Link onClick={() => openModal("modalTermsAndConditions")} to="#">
              Terms and Conditions
            </Link>
          </li>
          <li>
            <Link to="/contact">Contact us</Link>
          </li>
        </ul>
      </div>
      <Modal
        isOpen={!!modalState?.modalPrivacy}
        onClose={() => closeModal("modalPrivacy")}
      >
        <FooterPopupInformation title="Privacy Policy">
          <PrivacyPolicy />
        </FooterPopupInformation>
      </Modal>
      <Modal
        isOpen={!!modalState?.modalAccessibility}
        onClose={() => closeModal("modalAccessibility")}
      >
        <FooterPopupInformation title="Accessibility Statement for Patients Edge Website">
          <Accessibility />
        </FooterPopupInformation>
      </Modal>
      <Modal
        isOpen={!!modalState?.modalTermsAndConditions}
        onClose={() => closeModal("modalTermsAndConditions")}
      >
        <FooterPopupInformation title="Terms and Conditions">
          <TermsAndConditions />
        </FooterPopupInformation>
      </Modal>
    </footer>
  )
}
