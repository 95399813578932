import axios from "axios"
import { EMAIL_URL } from "../config/endpoints"

export const fetchAPI = async (body) =>
  new Promise(async (resolve, reject) => {
    try {
      const response = await axios(EMAIL_URL, {
        method: "POST",
        data: body,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return resolve(response.data)
    } catch (error) {
      return reject(error)
    }
  })
