import { Text } from "./templates/Text"

export const PrivacyPolicy = () => (
  <div>
    <Text className="italic">Last updated: September 08, 2023</Text>

    <Text>
      It's important to Patients Edge, Inc. to help our users retain their
      privacy when they take advantage of all the Internet has to offer. We
      believe your business is no one else's. Your privacy is important to you
      and to us. So we'll protect the information you share with us. To protect
      your privacy, Patients Edge, Inc. follows different principles in
      accordance with worldwide practices for customer privacy and data
      protection.
    </Text>
    <ul className="list-disc pl-2 md:pl-10">
      <li>
        We won’t sell or give away your name, mail address, phone number, email
        address or any other information to anyone.
      </li>
      <li>
        We’ll use state-of-the-art security measures to protect your information
        from unauthorized users.
      </li>
    </ul>
    <br />
    <br />
    <Text variation="subtitle">Notice</Text>

    <Text>
      We will ask you when we need information that personally identifies you
      (personal information) or allows us to contact you. Generally, this
      information is requested when you create a Registration ID on the site. We
      use your Personal Information for four primary purposes:
    </Text>
    <ul className="list-disc pl-2 md:pl-10">
      <li>
        To make the site easier for you to use by not having to enter
        information more than once.
      </li>
      <li>To help you quickly find services and information.</li>
      <li>To help us create content most relevant to you.</li>
      <li>
        To alert you to product upgrades, special offers, updated information
        and other new services from Patients Edge, Inc.
      </li>
    </ul>
    <br />
    <br />
    <Text variation="subtitle">Consent</Text>

    <Text>
      If you choose not to register or provide personal information, you can
      still use most of www.patientsedge.com. But you will not be able to access
      areas that require registration.
    </Text>
    <br />
    <Text variation="subtitle">Access</Text>

    <Text>
      We will provide you with the means to ensure that your personal
      information is correct and current. You may review and update this
      information at any time at the Visitor Center. There, you can:
    </Text>
    <ul className="list-disc pl-2 md:pl-10">
      <li> View and edit personal information you have already given us.</li>
      <li>
        Sign up for electronic newsletters about our services and products.
      </li>
      <li>
        Register. Once you register, you won't need to do it again. Wherever you
        go on www.patientsedge.com your information stays with you.
      </li>
    </ul>
    <br />
    <br />
    <Text variation="subtitle">Security</Text>
    <Text>
      Patients Edge, Inc. has taken strong measures to protect the security of
      your personal information and to ensure that your choices for its intended
      use are honored. We take strong precautions to protect your data from
      loss, misuse, unauthorized access or disclosure, alteration, or
      destruction. We guarantee your e-commerce transactions to be 100% safe and
      secure. When you place orders or access your personal account information,
      you're utilizing secure server software SSL, which encrypts your personal
      information before it's sent over the Internet. SSL is one of the safest
      encryption technologies available. In addition, your transactions are
      guaranteed under the Fair Credit Billing Act. This Act states that your
      bank cannot hold you liable for more than $50.00 in fraudulent credit card
      charges. If your bank does hold you liable for $50.00 or less, we'll cover
      your liability provided the unauthorized, fraudulent use of your credit
      card resulted through no fault of your own and from purchases made from us
      over our secure server. In the event of unauthorized use of your credit
      card, you must notify your credit card provider in accordance with its
      reporting rules and procedures. Patients Edge, Inc. strictly protects the
      security of your personal information and honors your choices for its
      intended use. We carefully protect your data from loss, misuse,
      unauthorized access or disclosure, alteration, or destruction. Your
      personal information is never shared outside the company without your
      permission, except under conditions explained above. Inside the company,
      data is stored in password-controlled servers with limited access. Your
      information may be stored and processed in the United States or any other
      country where Patients Edge, Inc , its subsidiaries, affiliates, business
      associates or agents are located. You also have a significant role in
      protecting your information. No one can see or edit your personal
      information without knowing your username and password, so do not share
      these with others.
    </Text>
    <br />
    <Text variation="subtitle">Enforcement</Text>
    <Text>
      If for some reason you believe Patients Edge, Inc. has not adhered to
      these principles, please notify us by email at privacy@patientsedge.com,
      and we will do our best to determine and correct the problem promptly. Be
      certain the words Privacy Policy are in the Subject line.
    </Text>
    <br />
    <Text variation="subtitle">User Profiles</Text>
    <Text>
      As mentioned above, every registered userr has a unique personal profile.
      Each profile is assigned a unique personal identification number, which
      helps us ensure that only you can access your profile. When you register,
      we create your profile, assign a personal identification number, then send
      this personal identification number back to your hard drive in the form of
      a cookie, which is a very small bit of code. This code is uniquely yours.
      It is your passport to seamless travel across www.patientsedge.com without
      having to fill out registration forms with information you've already
      provided. Even if you switch computers, you won't have to re-register –
      just use your Registration ID to identify yourself.
    </Text>
    <br />
    <Text variation="subtitle">What We Do with the Information You Share</Text>
    <Text>
      When you join us, you provide us with your contact information, including
      your email address. We use this information to send you updates about your
      order, questionnaires to measure your satisfaction with our service and
      announcements. We may ask for your credit card number and billing address
      to facilitate care arrangements. We use this information only to arrange
      services you order at that time. For your convenience, we do save billing
      information in case you want to order services again, but we don't use
      this information again without your permission. We occasionally hire other
      companies to provide limited services on our behalf, including packaging,
      mailing and delivering purchases, answering customer questions about
      products or services, sending postal mail and processing event
      registration. We will only provide those companies the information they
      need to deliver the service, and they are prohibited from using that
      information for any other purpose. Patients Edge, Inc.will disclose your
      personal information, without notice, only if required to do so by law or
      in the good faith belief that such action is necessary to: (a) conform to
      the edicts of the law or comply with legal process served on Patients
      Edge, Inc. or the site; (b) protect and defend the rights or property of
      Patients Edge, Inc. and its family of Websites, and, (c) act in urgent
      circumstances to protect the personal safety of users of Patients Edge,
      Inc., its Websites, or the public.
    </Text>
    <br />
    <Text variation="subtitle">Contact Us</Text>
    <Text>
      If you have any questions about this Privacy Policy, You can contact us:
    </Text>
    <ul className="list-disc pl-2 md:pl-10">
      <li> By email: privacy@patientsedge.com</li>
      <li>By phone number: (410) 252-5727</li>
      <li>By mail: 739 Chapel Ridge Road Lutherville, MD 21093, USA</li>
    </ul>
    <br />
    <Text>We try to respond to feedback within 3 business days.</Text>
  </div>
)
