import { Text } from "./templates/Text"

export const Accessibility = () => (
  <div>
    <Text className="italic">Last updated: September 08, 2023</Text>
    <br />
    <Text variation="subtitle">Conformance status</Text>
    <Text>
      Patients Edge is committed to ensuring digital accessibility for everyone,
      including those with disabilities. We are in the process of further
      developing our web services to conform with{" "}
      <a
        href="https://www.w3.org/WAI/standards-guidelines/wcag/"
        target="_blank"
        rel="noreferrer"
      >
        Web Content Accessibility Guidelines (WCAG)
      </a>
      level AA.
    </Text>

    <Text variation="subtitle">Feedback</Text>
    <Text>
      Please feel free to contact us about any accessibility issues or suggested
      improvements pertaining to our website:
    </Text>
    <ul className="list-disc pl-2 md:pl-10">
      <li>Phone: (410) 252-5727</li>
      <li>E-mail: accessibility@patientsedge.com</li>
      <li>Postal address: 739 Chapel Ridge Road Lutherville, MD 21093</li>
    </ul>
    <br />
    <Text>We try to respond to feedback within 3 business days.</Text>
  </div>
)
