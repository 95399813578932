import { ErrorMessage, Field, useField } from "formik"
import { randomIntFromInterval } from "../../utils/randomNumber"
import { Text } from "./Text"
import { BsSearch } from "react-icons/bs"

export const ErrorAlert = ({ children, ...rest }) => (
  <Text noMargin className="text-light-text_error text-sm" {...rest}>
    {children}
  </Text>
)

export const Input = ({ className, ...props }) => (
  <Field className={`${className}`} {...props} />
)

export const InputWrapper = ({
  className,
  label,
  type,
  name,
  full,
  searchIcon,
  ...props
}) => {
  const randomId = `${label}-${randomIntFromInterval()}`
  const [, meta] = useField(name)
  return (
    <div className={`mb-3 relative ${full ? "w-full" : "w-full"}`}>
      {label && (
        <div className="flex items-center">
          <label htmlFor={randomId} className="mb-2 mr-2">
            <Text variation="subtitle" noMargin>
              {label}
            </Text>
          </label>
        </div>
      )}
      <Field
        id={randomId}
        className={`bg-light-main px-4 py-3 ${
          meta.error && "border-light-text_error"
        } rounded-lg shadow-md mb-2 mr-2 w-full ${className && className}`}
        name={name}
        {...props}
        type={type}
      />
      {searchIcon && (
        <div className="absolute right-2 top-4 rounded-full h-8 w-8 bg-[#D0D81D] text-[#052B54] flex items-center justify-center">
          <BsSearch />
        </div>
      )}
      <ErrorMessage name={name} component={ErrorAlert} />
    </div>
  )
}

export const InputAreaWrapper = ({ className, label, name, ...props }) => {
  const [, meta] = useField(name)
  const randomId = `${label}-${randomIntFromInterval()}`
  return (
    <div className={`w-full ${className && className}`}>
      {label && (
        <div className="flex items-center">
          <label htmlFor={randomId} className="mb-2 mr-2">
            <Text variation="subtitle">{label}</Text>
          </label>
        </div>
      )}
      <Field
        id={randomId}
        as="textarea"
        className={`bg-light-main px-4 py-3 ${
          meta.error && "border-light-text_error"
        } rounded-lg shadow-md w-full ${className && className}`}
        name={name}
        {...props}
      />
      <ErrorMessage name={name} component={ErrorAlert} />
    </div>
  )
}

export const Select = ({
  children,
  className,
  label,
  name,
  noMargin,
  ...props
}) => {
  const [, meta] = useField(name)
  const randomId = `${label}-${randomIntFromInterval()}`
  return (
    <div
      className={`${!noMargin && "mb-3"} h-11 w-full relative ${
        className && className
      }`}
    >
      {label && (
        <div className="flex items-center">
          <label htmlFor={randomId} className="mb-2 mr-2">
            <Text variation="subtitle">{label}</Text>
          </label>
        </div>
      )}
      <div className={`select-wrapper ${!label && !noMargin && "mb-2"}`}>
        <Field
          id={randomId}
          as="select"
          className={`bg-light-main px-4 py-3 text-[#052B54] ${
            meta.error && "border-light-text_error"
          } rounded-lg shadow-md mb-2 mr-2 w-full ${className && className}`}
          name={name}
          {...props}
        >
          {children}
        </Field>
      </div>
      <ErrorMessage name={name} component={ErrorAlert} />
    </div>
  )
}
