import cs from "classnames"

const classesDefault = "text-light-text dark:text-dark-text"
const classesTitle = "md:text-6xl text-3xl font-bold mb-5"
const classesSubtitle = "md:text-2xl text-md"
const classesCaption = "text-light-text dark:text-dark-text"
const classesLegend = "text-xl"

const getCurrentType = (type) => {
  switch (type) {
    case "p":
      return classesDefault
    case "title":
      return classesTitle
    case "subtitle":
      return classesSubtitle
    case "caption":
      return classesCaption
    case "legend":
      return classesLegend
    default:
      return classesDefault
  }
}

export const Text = ({
  className,
  children,
  noMargin = false,
  variation: type = "p",
  ...rest
}) => {
  const classes = cs(getCurrentType(type), className, {
    "my-0": noMargin,
    "mb-5 mr-3": !noMargin,
  })
  if (type === "title") {
    return (
      <h1 {...rest} className={classes}>
        {children}
      </h1>
    )
  }

  if (type === "subtitle") {
    return (
      <h3 {...rest} className={classes}>
        {children}
      </h3>
    )
  }

  return (
    <p {...rest} className={classes}>
      {children}
    </p>
  )
}
