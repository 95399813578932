import React from "react"

export const Hidden = ({ screenSize, children, className, ...props }) => {
  let classes = ""

  switch (screenSize) {
    case "sm":
      classes = "hidden sm:block"
      break
    case "md":
      classes = "hidden md:block"
      break
    case "lg":
      classes = "hidden lg:block"
      break
    case "xl":
      classes = "hidden xl:block"
      break
    default:
      classes = ""
  }

  return (
    <div {...props} className={`${classes} ${className}`}>
      {children}
    </div>
  )
}
