import { Link as RouterLink } from "react-router-dom"
import cs from "classnames"

export const Link = ({ children, to, ...rest }) => {
  const classes = cs("font-medium text-light-link dark:text-dark-link")
  return (
    <RouterLink {...rest} to={to} className={classes}>
      {children}
    </RouterLink>
  )
}
