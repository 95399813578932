import { Form, Formik } from "formik"
import { useState } from "react"
import * as Yup from "yup"
import { Text } from "./templates/Text"
import { InputWrapper } from "./templates/Field"
import { Button } from "./templates/Button"
import { LiaChevronCircleLeftSolid } from "react-icons/lia"
import { BsArrowRight } from "react-icons/bs"
import { Image } from "./templates/Image"
import EPP2 from "../assets/images/persons/employer_page_person_2.svg"
import { getStorage, setStorage } from "../utils/localStore"
import { phoneRegExp } from "../utils/regex"
import { Hidden } from "./templates/Hidden"

export const EmployerFormFirstStep = ({ setPrevStep, setNextStep }) => {
  const [initialValues] = useState(
    getStorage("local", "employer_form") || {
      email: "",
      phonenumber: "",
      name: "",
      lastname: "",
      jobtitle: "",
    }
  )

  const signUpSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email.")
      .required("Your email is required."),
    name: Yup.string()
      .min(2, "Please enter a valid name.")
      .required("Your first name is required."),
    lastname: Yup.string()
      .min(2, "Please enter a valid last name.")
      .required("Your last name is required."),
    phonenumber: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number.")
      .required("Your phone number is required."),
    jobtitle: Yup.string()
      .min(2, "Please enter a valid job title.")
      .required("Your job title is required."),
  })

  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="md:order-1 md:w-3/6 w-full mt-3 md:flex md:justify-center md:items-center md:flex-col">
        <div>
          <div className="flex">
            <LiaChevronCircleLeftSolid
              onClick={() => setPrevStep()}
              className="md:h-12 md:w-12 w-8 h-8 md:mr- mr-2 md:-translate-y-1 -translate-y-0.5 cursor-pointer"
            />
            <Text className="font-bold text-2xl md:text-3xl">
              Personal Information
            </Text>
          </div>
          <div>
            <Formik
              validationSchema={signUpSchema}
              initialValues={initialValues}
              onSubmit={async (values) => {
                const oldValues = getStorage("local", "employer_form")
                setStorage("local", "employer_form", { ...oldValues, ...values })
                setNextStep()
              }}
            >
              {({ isSubmitting }) => (
                <div className="flex justify-center md:mt-5">
                  <Form className="w-4/5 mx-auto md:w-full">
                    <InputWrapper
                      className="min-w-full"
                      type="text"
                      name="name"
                      placeholder="First Name"
                    />
                    <InputWrapper
                      className="min-w-full"
                      type="text"
                      name="lastname"
                      placeholder="Last Name"
                    />
                    <InputWrapper
                      className="min-w-full"
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                    <InputWrapper
                      className="min-w-full"
                      name="phonenumber"
                      placeholder="Phone Number"
                    />
                    <InputWrapper
                      className="min-w-full"
                      type="text"
                      name="jobtitle"
                      placeholder="Job Title"
                    />
                    <div className="w-[200px] mt-10 mx-auto md:mx-0">
                      <Button
                        type="submit"
                        loading={isSubmitting}
                        className="bg-light-accent py-1 hover:bg-[#85A7C3]"
                      >
                        <span className="tracking-[0.2em] font-bold">
                          Continue
                          <BsArrowRight className="inline-block h-5 w-5 ml-2" />{" "}
                        </span>
                      </Button>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Hidden
        screenSize="sm"
        className="md:order-2 md:flex md:w-3/6 justify-end"
      >
        <Image className="self-end" src={EPP2} />
      </Hidden>
    </div>
  )
}
