export const getStorage = (storageType, key) => {
  if (!key || !storageType) return null
  if (storageType === "local") {
    return JSON.parse(window.localStorage.getItem(key))
  }
  if (storageType === "session") {
    return JSON.parse(window.sessionStorage.getItem(key))
  }
  return null
}

export const setStorage = (storageType, key, value) => {
  if (!key || !storageType) return null
  if (storageType === "local") {
    window.localStorage.setItem(key, JSON.stringify(value))
  }
  if (storageType === "session") {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  }
  return null
}
