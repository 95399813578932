import Logoimage from "../assets/images/brand/Logo_Variation.png"

const LogoVariation = ({ ...props }) => (
  <img
    className="w-22"
    {...props}
    src={Logoimage}
    alt="Patients Edge Logo"
  />
)
export default LogoVariation
