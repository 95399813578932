import React, { lazy, Suspense } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { GuestLayout } from "../layout"

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<></>}>
      <Component {...props} />
    </Suspense>
  )

export const ROUTES_DEFINITION = [
  {
    path: "/",
    label: "Home",
    labelParsed: "home",
    component: Loadable(lazy(async () => import("../pages/HomePage"))),
  },
  {
    path: "/employer",
    label: "Employer",
    labelParsed: "employer",
    component: Loadable(lazy(async () => import("../pages/Employer"))),
  },
  {
    path: "/patient",
    label: "Patient",
    labelParsed: "patient",
    component: Loadable(lazy(async () => import("../pages/Patient"))),
  },
  {
    path: "/brokers_and_advisors",
    label: "Brokers And Advisors",
    labelParsed: "brokersAndAdvisors",
    component: Loadable(lazy(async () => import("../pages/BrokersAndAdvisors"))),
  },
  {
    path: "/contact",
    label: "Contact",
    labelParsed: "contact",
    component: Loadable(lazy(async () => import("../pages/Contact"))),
  },
  {
    path: "*",
    label: "Not Found",
    labelParsed: "notFound",
    component: Loadable(lazy(async () => import("../pages/NotFound"))),
  },
]

const getRoutes = (routingArray) => {
  const newRoutingArray = [...routingArray]
  const result = newRoutingArray.map(({ path, component: Component }) => {
    const routeBuild = {
      path,
      element: (
        <GuestLayout>
          <Component />
        </GuestLayout>
      ),
    }
    return routeBuild
  })
  return result
}

const router = createBrowserRouter(getRoutes(ROUTES_DEFINITION))

export const routes = <RouterProvider router={router} />
