import { ROUTES_DEFINITION } from "../routes"

export const useConfig = () => {
  const getRoute = (labelToGet) =>
    ROUTES_DEFINITION.find(
      (route) => route.labelParsed.toLocaleLowerCase() === labelToGet
    )?.path ?? "/"

  return {
    getRoute,
  }
}
