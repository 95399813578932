import { useState } from "react"

export const useModal = () => {
  const [modalState, setModalState] = useState()

  const openModal = (id) => {
    setModalState((prev) => ({
      ...prev,
      [id]: true,
    }))
  }

  const closeModal = (id) => {
    setModalState((prev) => ({
      ...prev,
      [id]: false,
    }))
  }

  const toggleModal = (id) => {
    setModalState((prev) => ({
      ...prev,
      [id]: !prev[id],
    }))
  }

  return { modalState, openModal, closeModal, toggleModal }
}
