import { ScrollRestoration } from "react-router-dom"
import { Footer, Navbar } from "../components"

export const GuestLayout = ({ children }) => (
  <div id="layout relative">
    <ScrollRestoration />
    <Navbar />
    <main className="w-full min-h-screen">{children}</main>
    <Footer />
  </div>
)
