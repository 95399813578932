import { Form, Formik } from "formik"
import { useState } from "react"
import * as Yup from "yup"
import {
  ErrorAlert,
  InputAreaWrapper,
  InputWrapper,
  Select,
} from "./templates/Field"
import { Button } from "./templates/Button"
import { LiaChevronCircleLeftSolid } from "react-icons/lia"
import { BsArrowRight } from "react-icons/bs"
import EPP3 from "../assets/images/persons/employer_page_person_3.svg"
import { Image } from "./templates/Image"
import { getStorage, setStorage } from "../utils/localStore"
import { Text } from "./templates/Text"
import { Hidden } from "./templates/Hidden"
import { fetchAPI } from "../service/fetch"
import { isEmpty } from "../utils/objects"

export const EmployerFormSecondStep = ({ setPrevStep, setNextStep }) => {
  const [initialValues] = useState(
    getStorage("local", "employer_form") || {
      organizationName: "",
      numberEmployeesInHealthPlan: "",
      organizationCityAndState: "",
      health: "",
      problemDescription: "",
    }
  )
  const [error, setError] = useState(null)

  const signUpSchema = Yup.object().shape({
    organizationName: Yup.string()
      .min(2, "Please enter a valid organization name.")
      .required("The organization name is required."),
    organizationCityAndState: Yup.string()
      .min(2, "Please enter a valid organization location.")
      .required("The organization location is required."),
    problemDescription: Yup.string()
      .min(5, "Please provide a more detailed description of your problem.")
      .required("A problem description is required."),
    numberEmployeesInHealthPlan: Yup.string()
      .min(2, "Please enter a valid number of employees.")
      .required("The number of employees  is required."),
    healthBenefitFundingMethod: Yup.string()
      .min(2, "Please enter a valid health benefit funding method.")
      .required("The health benefit funding method is required."),
  })

  return (
    <div className="flex flex-col md:flex-row w-full">
      <div className="md:order-1 md:w-3/6 w-full mt-3 md:flex md:justify-center md:items-center md:flex-col overflow-hidden">
        <div>
          <div className="flex">
            <LiaChevronCircleLeftSolid
              onClick={() => setPrevStep()}
              className="md:h-12 md:w-12 w-8 h-8 md:mr- mr-2 md:-translate-y-1 -translate-y-0.5 cursor-pointer"
            />
            <Text className="font-bold text-2xl md:text-3xl">
              Company Information
            </Text>
          </div>
          <div>
            <Formik
              validationSchema={signUpSchema}
              initialValues={initialValues}
              onSubmit={async (values) => {
                try {
                  const oldValues = getStorage("local", "employer_form")
                  if (isEmpty({ ...oldValues, ...values }))
                    throw Error("Form data not found")
                  const allData = {
                    ...oldValues,
                    ...values,
                    subject: "Employer Form Submission",
                  }
                  setStorage("local", "employer_form", {})
                  fetchAPI(allData)
                  setError(false)
                  setNextStep()
                } catch (error) {
                  setError(true)
                }
              }}
            >
              {({ isSubmitting }) => (
                <div className="flex justify-center md:mt-5">
                  <Form className="w-4/5 mx-auto md:w-full">
                    <div className="grid md:grid-cols-2 grid-cols-1 md:grid-rows-4 gap-1">
                      <InputWrapper
                        type="text"
                        name="organizationName"
                        placeholder="Organization Name"
                        className="order-1 md:order-none"
                      />
                      <Select
                        name="numberEmployeesInHealthPlan"
                        className="order-3 md:order-none"
                      >
                        <option value="">
                          Number of Employees in Healthplan
                        </option>
                        <option value="1-100">1-100</option>
                        <option value="101-250">101-250</option>
                        <option value="251-500">251-500</option>
                        <option value="1501-5000">1501-5000</option>
                        <option value="+5000">+5000</option>
                      </Select>
                      <InputWrapper
                        type="text"
                        name="organizationCityAndState"
                        placeholder="Organization City and State"
                        className="order-2 md:order-none"
                      />
                      <Select
                        name="healthBenefitFundingMethod"
                        className="order-4 md:order-none"
                      >
                        <option value="">Health Benefit Funding method</option>
                        <option value="Fully insured">Fully insured</option>
                        <option value="Self insured">Self insured</option>
                      </Select>
                      <br />
                      <br />
                      <InputAreaWrapper
                        className="md:col-span-2 order-5 md:order-none"
                        type="textarea"
                        name="problemDescription"
                        placeholder="What problem can we help you solve?"
                        rows="4"
                      />
                      <div className="md:col-start-2 md:col-end-3 md:mt-24 mt-5 order-6 md:order-none">
                        <div className="w-[200px] md:float-right">
                          <Button
                            type="submit"
                            loading={isSubmitting}
                            className="bg-light-accent py-1 hover:bg-[#85A7C3]"
                          >
                            <span className="tracking-[0.2em] font-bold">
                              Complete
                              <BsArrowRight className="inline-block h-5 w-5 ml-2" />{" "}
                            </span>
                          </Button>
                        </div>
                      </div>
                      <div className="mt-3">
                        {error && (
                          <ErrorAlert>Oops! Something went wrong.</ErrorAlert>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Hidden
        screenSize="sm"
        className="md:order-2 md:flex md:w-3/6 justify-end mt-20 overflow-hidden md:h-[80vh]"
      >
        <Image className="md:h-[150vh]" src={EPP3} />
      </Hidden>
    </div>
  )
}
