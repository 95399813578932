import cs from "classnames"
import { FaTimes } from "react-icons/fa"
export const Modal = ({ isOpen, onClose, className, children, ...rest }) => {
  const modalClasses = cs(
    "relative bg-light-main rounded-xl z-50 p-4 pr-10 h-[80vh] h-[50vh] w-4/5 md:w-1/2 mx-auto overflow-auto",
    className
  )

  const backdropClasses = cs(
    "fixed top-0 left-0 w-full h-full bg-[#111827] bg-opacity-60 z-40 flex justify-center items-center"
  )

  if (!isOpen) return null

  return (
    <div className={backdropClasses} onClick={onClose} {...rest}>
      <div className={modalClasses} onClick={(e) => e.stopPropagation()}>
        <button className="fixed md:right-[27%] right-[19%]" onClick={onClose}>
          <FaTimes className="h-5 w-5 text-light-text_primary dark:text-dark-text_primary" />
        </button>
        {children}
      </div>
    </div>
  )
}
