import { Link as RouterLink, NavLink, useLocation } from "react-router-dom"
import { useClickOutside, useConfig } from "../hooks"
import Logo from "./Logo"
import { useRef, useState } from "react"
import { Transition } from "@headlessui/react"

const linkClass =
  "text-lg md:text-base md:text-light-link text-light-main font-bold md:font-thin hover:text-light-accent hover:underline transition-all"
const activeClassName =
  "text-lg md:text-base font-bold md:font-thin md:underline text-light-main md:text-light-accent md:hover:text-light-accent md:hover:underline transition-all"

const NAV_ITEMS = {
  SECTION: {
    ONE: [
      {
        label: "Employers",
        path: "/employer",
      },
      {
        label: "Patients",
        path: "/patient",
      },
      {
        label: "Brokers and Advisors",
        path: "/brokers_and_advisors",
      },
    ],
    TWO: [
      {
        label: "Contact",
        path: "/contact",
      },
    ],
  },
}

const UnorderedList = ({ list, hideMenu }) => (
  <ul className="flex md:items-center md:space-x-10 space-y-3 md:space-y-0 flex-col md:flex-row">
    {list.map(({ path, label }, index) => (
      <li key={index}>
        <NavLink
          to={path}
          className="block text-light-text"
          aria-current="page"
          onClick={() => {
            if (hideMenu) hideMenu()
          }}
        >
          {({ isActive }) => (
            <span className={isActive ? activeClassName : linkClass}>
              {label}
            </span>
          )}
        </NavLink>
      </li>
    ))}
  </ul>
)

export const Navbar = () => {
  const { getRoute } = useConfig()
  const { pathname } = useLocation()
  const clickRef = useRef()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  useClickOutside(clickRef, () => {
    setShowMobileMenu(false)
  })
  return (
    <>
      <nav className="w-full md:px-20 md:mt-10 h-16 default-navbar z-40 absolute top-0">
        <div className="flex-nowrap items-center justify-between mx-auto h-full container md:flex hidden">
          <RouterLink to={getRoute("home")} className="flex items-center w-2/6">
            <Logo variation={pathname === "/" ? "white" : "none"} />
          </RouterLink>
          <div className="w-4/6">
            <UnorderedList list={NAV_ITEMS.SECTION.ONE} />
          </div>
          <div className="flex justify-end w-1/6">
            <UnorderedList list={NAV_ITEMS.SECTION.TWO} />
          </div>
        </div>
        <div className="bg-[transparent] flex md:hidden justify-between relative items-center px-5 h-20">
          <Logo className="w-24 h-24" />
          <div
            id="hamburguerMenuIcon"
            onClick={() => {
              setShowMobileMenu(!showMobileMenu)
            }}
            className="w-8 h-10 mt-4 cursor-pointer absolute right-5 top-4"
          >
            <div className="h-1 mb-1 rounded-md w-full bg-[#D1D81E]"></div>
            <div className="h-1 mb-1 rounded-md w-[90%] float-right bg-[#D1D81E]"></div>
            <div className="h-1 mb-1 rounded-md w-[85%] float-right bg-[#D1D81E]"></div>
          </div>
        </div>

        <Transition
          className="bg-light-secondary overflow-hidden ease-linear -z-20 absolute top-[3.9rem] right-0 left-0 pl-5 py-5"
          show={showMobileMenu}
          enter="transition-all duration-200"
          enterFrom="h-[0px] -z-10 opacity-0"
          enterTo="h-[200px] z-10 opacity-100"
          leave="transition-all duration-200"
          leaveFrom="h-[200px] z-10 opacity-100"
          leaveTo="h-[0px] -z-10 opacity-0"
          ref={clickRef}
        >
          <UnorderedList
            list={[...NAV_ITEMS.SECTION.ONE, ...NAV_ITEMS.SECTION.TWO]}
            hideMenu={() => setShowMobileMenu(false)}
          />
        </Transition>
      </nav>
    </>
  )
}
